import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// ASCII art for the console
const asciiArt = `

 :::::::: ::::::::::: ::::::::  :::::::::        ::::::::  :::    ::: ::::::::::: ::::::::::: :::       :::     :::     :::::::::  ::::::::::
:+:    :+:    :+:    :+:    :+: :+:    :+:      :+:    :+: :+:    :+:     :+:         :+:     :+:       :+:   :+: :+:   :+:    :+: :+:
+:+           +:+    +:+    +:+ +:+    +:+      +:+        +:+    +:+     +:+         +:+     +:+       +:+  +:+   +:+  +:+    +:+ +:+
+#++:++#++    +#+    +#+    +:+ +#++:++#+       +#++:++#++ +#++:++#++     +#+         +#+     +#+  +:+  +#+ +#++:++#++: +#++:++#:  +#++:++#
       +#+    +#+    +#+    +#+ +#+                    +#+ +#+    +#+     +#+         +#+     +#+ +#+#+ +#+ +#+     +#+ +#+    +#+ +#+
#+#    #+#    #+#    #+#    #+# #+#             #+#    #+# #+#    #+#     #+#         #+#      #+#+# #+#+#  #+#     #+# #+#    #+# #+#
 ########     ###     ########  ###              ########  ###    ### ###########     ###       ###   ###   ###     ### ###    ### ##########

`;

// Constants
const CONFIG = {
  ANIMATION: {
    DEFAULT_DURATION: 1.25,
    DEFAULT_EASE: "expo.inOut",
    BLUR_DURATION: 1,
    NAV_DURATION: 0.45,
    DEBOUNCE_DELAY: 200,
  },
  SCROLL: {
    LERP: 0.1,
    INFINITE: false,
    SMOOTH_WHEEL: true,
  },
  DIRECTIONS: {
    NEXT: 1,
    PREV: -1,
  },
};

// Performance optimizations - Use WeakMap for DOM references
const DOMCache = new WeakMap();

// Initialize GSAP
gsap.registerPlugin(ScrollTrigger);

// State management
const State = {
  lenis: null,
  isPickerOpen: false,
  isNavOpen: true,
  lastScrollDirection: "up",
  isAnimating: false,
};

// Utility functions
const utils = {
  debounce(func, wait) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  },

  safeQuerySelector(selector, context = document) {
    try {
      return context.querySelector(selector);
    } catch (error) {
      console.error(`Invalid selector: ${selector}`, error);
      return null;
    }
  },

  safeQuerySelectorAll(selector, context = document) {
    try {
      return [...context.querySelectorAll(selector)];
    } catch (error) {
      console.error(`Invalid selector: ${selector}`, error);
      return [];
    }
  },

  validateElement(element, fallback = null) {
    return element instanceof HTMLElement ? element : fallback;
  },
};

// Smooth Scrolling Controller
class ScrollController {
  static init() {
    try {
      if (typeof Lenis !== "function") {
        throw new Error("Lenis is not loaded");
      }

      State.lenis = new Lenis(CONFIG.SCROLL);

      // Optimize scroll handler using RAF
      State.lenis.on("scroll", () => {
        if (!State.isAnimating) {
          requestAnimationFrame(() => ScrollTrigger.update());
        }
      });

      // Optimize animation frame handling
      const scrollFn = (time) => {
        State.lenis?.raf(time);
        requestAnimationFrame(scrollFn);
      };

      requestAnimationFrame(scrollFn);
    } catch (error) {
      console.error("Failed to initialize smooth scrolling:", error);
    }
  }

  static destroy() {
    State.lenis?.destroy();
    State.lenis = null;
  }
}

// Enhanced Slideshow Class with better error handling and performance
class Slideshow {
  constructor(element) {
    if (!utils.validateElement(element)) {
      throw new Error("Invalid slideshow element");
    }

    this.DOM = {
      el: element,
      slides: utils.safeQuerySelectorAll(".slide__content", element),
      slidesInner: [],
    };

    // Validate required elements
    if (!this.DOM.slides.length) {
      throw new Error("No slides found");
    }

    // Cache slide inner elements
    this.DOM.slidesInner = this.DOM.slides
      .map((slide) => utils.safeQuerySelector(".slide__img", slide))
      .filter(Boolean);

    this.current = 0;
    this.slidesTotal = this.DOM.slides.length;

    // Set initial state
    this.DOM.slides[0]?.classList.add("slide__current");
  }

  navigate(direction) {
    if (State.isAnimating || !this.DOM.slides.length) return false;

    State.isAnimating = true;
    const previous = this.current;

    // Calculate next index with bounds checking
    this.current =
      direction === CONFIG.DIRECTIONS.NEXT
        ? (this.current + 1) % this.slidesTotal
        : (this.current - 1 + this.slidesTotal) % this.slidesTotal;

    const [currentSlide, upcomingSlide] = [
      this.DOM.slides[previous],
      this.DOM.slides[this.current],
    ];

    const [currentInner, upcomingInner] = [
      this.DOM.slidesInner[previous],
      this.DOM.slidesInner[this.current],
    ];

    if (!currentSlide || !upcomingSlide) {
      State.isAnimating = false;
      return false;
    }

    // Optimized animation timeline
    gsap
      .timeline({
        defaults: {
          duration: CONFIG.ANIMATION.DEFAULT_DURATION,
          ease: CONFIG.ANIMATION.DEFAULT_EASE,
        },
        onStart: () => {
          upcomingSlide.classList.add("slide__current");
          gsap.set(upcomingSlide, { zIndex: 99 });
        },
        onComplete: () => {
          currentSlide.classList.remove("slide__current");
          gsap.set(upcomingSlide, { zIndex: 1 });
          State.isAnimating = false;
        },
      })
      .addLabel("start", 0)
      .to(
        currentSlide,
        {
          duration: 0.65,
          ease: "power1.inOut",
          scale: 0.9,
          borderRadius: "2rem",
          autoAlpha: 0.2,
        },
        "start",
      )
      .to(
        currentSlide,
        {
          yPercent: -direction * 20,
          autoAlpha: 0,
        },
        "start+=0.1",
      )
      .fromTo(
        upcomingSlide,
        {
          autoAlpha: 1,
          scale: 1,
          borderRadius: "inherit",
          yPercent: direction * 100,
        },
        { yPercent: 0 },
        "start+=0.1",
      )
      .fromTo(
        upcomingInner,
        { yPercent: -direction * 50 },
        { yPercent: 0 },
        "start+=0.1",
      );
  }

  next() {
    this.navigate(CONFIG.DIRECTIONS.NEXT);
  }

  prev() {
    this.navigate(CONFIG.DIRECTIONS.PREV);
  }
}

// UI Controllers
const UIController = {
  initColorPicker() {
    try {
      const picker = new iro.ColorPicker("#picker", {
        width: 250,
        color: "hsl(89, 51%, 88%)",
        layout: [
          {
            component: iro.ui.Slider,
            options: {
              sliderType: "hue",
              handleRadius: 9,
              padding: 0,
              margin: 0,
              borderColor: "var(--color-light)",
              borderWidth: 3,
            },
          },
        ],
      });

      // Optimize color updates with RAF
      picker.on(["color:init", "color:change"], (color) => {
        requestAnimationFrame(() => this.updateColors(color.hsl.h));
      });
    } catch (error) {
      console.error("Failed to initialize color picker:", error);
    }
  },

  updateColors(hue) {
    const colorVariables = {
      "--color-accent": `hsl(${hue}, 51%, 88%)`,
      "--color-accent-2": `hsl(${hue}, 51%, 92%)`,
      "--color-light": `hsl(${(hue + 326) % 360}, 60%, 96%)`,
      "--color-dark": `hsl(${(hue + 162) % 360}, 12%, 73%)`,
      "--color-darkest": `hsl(${(hue - 2) % 360}, 52%, 4%)`,
    };

    for (const [property, value] of Object.entries(colorVariables)) {
      document.documentElement.style.setProperty(property, value);
    }
  },

  initToggles() {
    const footerPicker = utils.safeQuerySelector("#footerPicker");
    const navToggle = utils.safeQuerySelector("#navToggle");

    if (footerPicker) {
      ["click", "touchstart"].forEach((event) =>
        footerPicker.addEventListener(event, (e) => {
          e.preventDefault();
          this.togglePicker(footerPicker);
        }),
      );
    }

    if (navToggle) {
      navToggle.addEventListener("click", (e) => {
        e.preventDefault();
        this.toggleNav(navToggle);
      });
    }
  },

  togglePicker(footerPicker) {
    gsap.to("#footerWave", {
      filter: "blur(100px)",
      display: "none",
      duration: CONFIG.ANIMATION.BLUR_DURATION,
      ease: "power3.out",
    });

    const animations = {
      open: {
        "#pickerContainer": {
          width: "260px",
          height: "25px",
          opacity: 1,
        },
        "#footerPicker": {
          width: "260px",
          height: "25px",
        },
      },
      close: {
        "#pickerContainer": {
          width: 0,
          height: 0,
          opacity: 0,
        },
        "#footerPicker": {
          width: "16px",
          height: "16px",
        },
      },
    };

    const animation = State.isPickerOpen ? animations.close : animations.open;

    Object.entries(animation).forEach(([selector, props]) => {
      const element = utils.safeQuerySelector(selector);
      if (element) {
        gsap.to(element, {
          ...props,
          duration: CONFIG.ANIMATION.NAV_DURATION,
          ease: "power3.out",
        });
      }
    });

    State.isPickerOpen = !State.isPickerOpen;
    footerPicker?.classList.toggle("active");
  },

  toggleNav(navToggle) {
    gsap.to("#navWave", {
      filter: "blur(100px)",
      display: "none",
      duration: 5,
      ease: "power3.out",
    });

    const animations = {
      open: {
        "#navContainer": { top: "0" },
        "#navBlur": { top: "0", delay: 0.05 },
        "#nav": {
          background: "hsla(0, 0%, 0%, 0.4)",
          pointerEvents: "auto",
          delay: 0.25,
        },
      },
      close: {
        "#navContainer": { top: "-210px" },
        "#navBlur": { top: "-40%", delay: 0.05 },
        "#nav": {
          background: "hsla(0, 0%, 0%, 0.0)",
          pointerEvents: "none",
          delay: 0.25,
        },
      },
    };

    const animation = State.isNavOpen ? animations.close : animations.open;

    Object.entries(animation).forEach(([selector, props]) => {
      const element = utils.safeQuerySelector(selector);
      if (element) {
        gsap.to(element, {
          ...props,
          duration: CONFIG.ANIMATION.NAV_DURATION,
          ease: "power3.out",
        });
      }
    });

    State.isNavOpen = !State.isNavOpen;
    navToggle?.classList.toggle("navbar__active");
  },
};

// Scroll animations
const ScrollAnimations = {
  init() {
    const contentElements = utils.safeQuerySelectorAll(".content");

    contentElements.forEach((el, position) => {
      const isLast = position === contentElements.length - 1;

      gsap
        .timeline({
          scrollTrigger: {
            trigger: el,
            start: "top top",
            end: "+=100%",
            scrub: true,
          },
        })
        .to(el, {
          ease: "none",
          scale: 0.9,
          rotateX: "-35deg",
          borderRadius: "2.85rem",
          filter: isLast
            ? "none"
            : "brightness(0.2) saturate(300%) blur(1.5px)",
          "--shadowpercent": isLast ? "0%" : "115%",
          "--shadowoffset": isLast ? "0%" : "15%",
          startAt: {
            filter: "brightness(1) saturate(100%)",
            borderRadius: "0.5rem",
          },
        });
    });

    // Initialize scroll-based nav toggle
    ScrollTrigger.create({
      start: "top top",
      end: "bottom bottom",
      onUpdate: (self) => {
        const currentScrollDirection = self.direction === 1 ? "down" : "up";

        if (currentScrollDirection !== State.lastScrollDirection) {
          if (currentScrollDirection === "down" && State.isNavOpen) {
            UIController.toggleNav(utils.safeQuerySelector("#navToggle"));
          }
          State.lastScrollDirection = currentScrollDirection;
        }
      },
    });
  },
};

// Main initialization
function init() {
  try {
    ScrollController.init();
    ScrollAnimations.init();
    UIController.initColorPicker();
    UIController.initToggles();

    console.log("Application initialized successfully");
    console.log(asciiArt);

    // Initialize slideshows
    const slideshows = utils.safeQuerySelectorAll(".slide");
    slideshows.forEach((slides, index) => {
      try {
        const slideshow = new Slideshow(slides);

        const prevButton = utils.safeQuerySelector(
          `.slide__nav__item__prev[data-slideshow="${index}"]`,
        );
        const nextButton = utils.safeQuerySelector(
          `.slide__nav__item__next[data-slideshow="${index}"]`,
        );

        prevButton?.addEventListener("click", () => slideshow.prev());
        nextButton?.addEventListener("click", () => slideshow.next());
      } catch (error) {
        console.error(`Failed to initialize slideshow ${index}:`, error);
      }
    });

    // Initialize ScrollTrigger refresh handling
    const debouncedRefresh = utils.debounce(() => {
      ScrollTrigger.refresh();
    }, CONFIG.ANIMATION.DEBOUNCE_DELAY);

    window.addEventListener("resize", debouncedRefresh, { passive: true });
    ScrollTrigger.refresh();
  } catch (error) {
    console.error("Failed to initialize application:", error);
  }
}

// Cleanup function
function cleanup() {
  ScrollController.destroy();
  ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
  window.removeEventListener("resize", utils.debounce);
}

// Event listeners
document.addEventListener("DOMContentLoaded", init, { passive: true });
window.addEventListener("beforeunload", cleanup);

// Error boundary
window.addEventListener("error", (event) => {
  console.error("Global error caught:", event.error);
  cleanup();
});
